import React, { useRef, useEffect } from 'react';
import Input from 'components/forms/input';
import Button from 'components/button/button';
import { passwordValidatonIssue, emailValidationIssue } from 'components/forms/form-helpers';
import store from 'store/store.js';
import { navigate } from "gatsby";
import { Event } from "./../../helpers/analytics/event-tracking"

const UserDetailsRegistry = ({ updatePlatformRegistration,  data_prep_registration_reducer }) => {
  const non_work_email_types = ['@gmail.', '@hotmail.', '@yahoo.'];
  const formRef = useRef(null);

  useEffect(() => {
    // sets all form field with work email or preexisting form data
    const _store = store.getState(); // get latest access to the store outside of function scope
    const _data_prep_registration_reducer = _store.registrationReducer.data_prep[_store.registrationReducer.data_prep.length - 1];
    const { email, password, work_email, first_name, surname, role, company } = _data_prep_registration_reducer;

    if (!email) {
      updatePlatformRegistration('step', 0)
    }

    if (!non_work_email_types.some(v => email.includes(v))){
      const work_email_el = document.querySelector('.UserDetailsRegistry__work_email input')
      work_email_el.value = email;
    }
    else {
      const work_email_el = document.querySelector('.UserDetailsRegistry__work_email input')
      work_email_el.value = work_email;
    }

    const first_name_el = document.querySelector('.UserDetailsRegistry__first_name input')
    first_name_el.value = first_name;

    const surname_el = document.querySelector('.UserDetailsRegistry__surname input')
    surname_el.value = surname;

    const password_el = document.querySelector('.UserDetailsRegistry__password input')
    password_el.value =  password;

    const role_el = document.querySelector('.UserDetailsRegistry__role input')
    role_el.value =  role;

    const company_el = document.querySelector('.UserDetailsRegistry__company input')
    company_el.value =  company;
  }, [])

  useEffect(() => {
    // adds and clears error classes
    const { error } = data_prep_registration_reducer;

    if (error && error.includes('email')) {
      document.querySelector('.UserDetailsRegistry__work_email input').classList.add('k-form-error')
    }

    if (error && error.includes('password')) {
      document.querySelector('.UserDetailsRegistry__password input').classList.add('k-form-error')
    }

    return () => {
      updatePlatformRegistration('error', null)
    };
  }, []);

  const setErrorMessage = (message) => {
    const inputs = document.querySelectorAll('.UserDetailsRegistry input');
    inputs.forEach(input => input.classList.remove('k-form-error'));

    updatePlatformRegistration('error', message);
  }

  const onClick = (e) => {
    e.preventDefault();
    const form = formRef.current;

    let form_inputs = Array.from(form.children);
    form_inputs = form_inputs.filter(el => el.classList.contains("form-group"));
    form_inputs.forEach(el => {
      updatePlatformRegistration(el.children[0].name, el.children[0].value);
    });

    const _store = store.getState();  // get latest access to the store outside of function scope
    const _data_prep_registration_reducer = _store.registrationReducer.data_prep[_store.registrationReducer.data_prep.length - 1];

    const { email, password, work_email, first_name, surname } = _data_prep_registration_reducer;
    if (!first_name) {
      setErrorMessage('Please include a first name', '.UserDetailsRegistry__first_name input');
      Event('error', 'ml-data-prep user-details-registry', 'first name invalid');
      return;
    }

    if (!surname) {
      setErrorMessage('Please include a last name', '.UserDetailsRegistry__surname input');
      Event('error', 'ml-data-prep user-details-registry', 'surname name invalid')
      return;
    }

    const password_issue = passwordValidatonIssue(password, setErrorMessage);
    if (password_issue) {
      return;
    }

    const email_issue = emailValidationIssue(email, setErrorMessage);
    const work_email_issue = emailValidationIssue(work_email, setErrorMessage, true);
    if (email_issue || work_email_issue) {
      if (email_issue)
        Event('error', 'ml-data-prep user-details-registry', 'primary email invalid')
      if (work_email_issue)
        Event('error', 'ml-data-prep user-details-registry', 'work email invalid')
      return;
    }

    navigate('/ml-data-prep-privacy-policy/');
  }

 return (
  <div className="col-md-6 k-gradient-green-blue">
    <form ref={formRef} className="UserDetailsRegistry">
      <Input name="first_name"
            type="text"
            placeholder="First name"
            className="UserDetailsRegistry__first_name fs-mask-without-consent"
            required >
      </Input>

      <Input name="surname"
            type="text"
            placeholder="Last name"
            className="UserDetailsRegistry__surname fs-mask-without-consent"
            required >
      </Input>
    <Input name="role"
          type="text"
          placeholder="Role"
          className="UserDetailsRegistry__role fs-mask-without-consent"
          required >
    </Input>

    <Input name="company"
          type="text"
          placeholder="Company"
          className="UserDetailsRegistry__company fs-mask-without-consent"
          required >
    </Input>

    <Input name="work_email"
          type="email"
          placeholder="Work Email"
          className="UserDetailsRegistry__work_email fs-mask-without-consent">
    </Input>

    <Input name="password"
          type="password"
          placeholder="Password"
          className="UserDetailsRegistry__password fs-mask-without-consent"
          required >
    </Input>

    <Button className="UserDetailsRegistry__submit btn k-text--heading-work btn-block btn-xs-inline-block d-flex align-items-center justify-content-center p-2 mt-3"
            id={`UserDetailsRegistry__submit`}
            callback={onClick}>
      Next
    </Button>
  </form>
  { data_prep_registration_reducer.error
    ? <div className="mt-3 w-100 text-red" role="alert">
        {data_prep_registration_reducer.error}
      </div>
    : null
  }
  </div>
  )
}

export default UserDetailsRegistry