import React from 'react';
import { connect } from 'react-redux';
import { UPDATE_PLATFORM_REGISTRATION } from '../store/actions';
import { graphql } from 'gatsby';
import Image from 'components/image/image';
import DataPrepSubpageWrapper from 'components/data-prep/data-prep-subpage-wrapper';
import UserDetailsRegistry from 'components/data-prep/user-details-registry';

const DataPrepRegistration = ({ data, data_prep_registration_reducer, updatePlatformRegistration }) => {
  const d = data.landingPagesYaml.registration;
  const { step } = data_prep_registration_reducer;

  return (
    <DataPrepSubpageWrapper step={step} data={d} updatePlatformRegistration={updatePlatformRegistration}>
      <div className="DataPrepSectionContainer__RegistrationSignUpBox row k-background-box">
        <div className="col-md-6 d-lg-flex d-none">
          <Image image_url={'content/data-quote-simon-james/data-quote-simon-james.png'}
                alt={`"A machine learning accelerator the likes you have never seen." by Simon James, Group VP of Data-Science, Publicis Sapient`}
                className="DataPrepSectionContainer__quote"/>
        </div>
        <UserDetailsRegistry updatePlatformRegistration={updatePlatformRegistration}
                              data_prep_registration_reducer={data_prep_registration_reducer}/>
      </div>
    </DataPrepSubpageWrapper>
  )
}

const mapStateToProps = state => {
  return {
    data_prep_registration_reducer: state.registrationReducer.data_prep[state.registrationReducer.data_prep.length - 1]
  }
}

const mapDispatchToProps = dispatch => ({
  updatePlatformRegistration: (field, data) => {
    dispatch(UPDATE_PLATFORM_REGISTRATION(field, data))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(DataPrepRegistration)

export const pageQuery = graphql`
  query {
    landingPagesYaml {
      registration {
        title
        subtitle
        section
        data_policy
      }
    }
  }
`