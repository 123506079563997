import React, {useEffect} from 'react';
import Image from 'components/image/image';
import './page-counter.sass';

const CheckBubble = () => (
  <div className="CheckBubble">
    <div>
      <Image image_url="icons/icon-checkmark.svg"
              alt={`checkmark inside colored bubble`}
              className=""/>
    </div>
  </div>
)

const NumberBubble = ({ number, is_active }) => (
  <div className={`NumberBubble ${is_active ? 'NumberBubble__active' : null}`}>
    <div>
      {number}
    </div>
  </div>
)

const PageCounter = ({ step, parts }) => (
  <div className="PageCounter d-flex justify-content-center mt-3">
    {parts.map((_, i) => {
      return (
        <div className="PageCounter__item d-flex" id={i + 1} key={i}>
          { (i + 1) > step
            ? <NumberBubble number={i + 1} is_active={false}/>
              : ( i+ 1) === step
                ? <NumberBubble number={i + 1} is_active={true}/>
                : <CheckBubble />
          }
          <p className="mr-4" >{parts[i]}</p>
        </div>
      )
    })}
  </div>
)

export default PageCounter;