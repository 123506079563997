import React, { useEffect, useState } from 'react';
import { navigate } from "gatsby"
import Layout from 'components/layout';
import SEO from 'components/seo/seo';
import Image from 'components/image/image';
import PageCounter from 'components/data-prep/page-counter/page-counter';
import 'components/data-prep/data-prep.sass';

const DataPrepSubpageWrapper = ({ data, step, children, updatePlatformRegistration }) => {
  const [isWelcome, setIsWelcome] = useState(null)

  if (typeof window !== 'undefined' && window.location) {
    if (step === 0) {
      navigate('/ml-data-prep/');
      return <React.Fragment></React.Fragment>;
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && window.location) {
      switch (window.location.pathname) {
        case '/ml-data-prep-registration/':
          updatePlatformRegistration('step', 1);
          break;
        case '/ml-data-prep-privacy-policy/':
          updatePlatformRegistration('step', 2);
          break;
        case '/ml-data-prep-waitlist/':
          updatePlatformRegistration('step', 3);
          break;
        case '/ml-data-prep-welcome/':
          setIsWelcome(true)
          updatePlatformRegistration('step', 3);
          break;
        default:
          updatePlatformRegistration('step', 0);
          break;
      }
    }
  }, [])

  return (
    <Layout hide_contact={true}
            footer_site_map={false}
            footerClass={'Footer-reverse Footer-short'}>
      <SEO title="ML Data Prep"
          keywords={['AI', 'Machine Learning', 'AutoML', 'AI Consulting', 'ML', 'Artificial Intelligence']}/>

      <div className="DataPrepSectionContainer__container k-bg--grey">
        <div className="DataPrepSectionContainer container-fluid pt-3 pb-5">

          <h2 className="text-center">{data.title[step - 1]}</h2>

          <div className={`DataPrepSectionContainer__subtitle-container ${step === 1 ? 'DataPrepSectionContainer__subtitle-container--free-for-beta--mobile' : null}`}>
            { step === 1 ? <div></div> : null }
              <h4 className="text-center">{isWelcome ? data.subtitle[3] : data.subtitle[step - 1]}</h4>
              { step === 1
                ? <React.Fragment>
                  <Image image_url="content/data-free-for-beta/data-free-for-beta.png"
                        alt={`crossing out kortical platform price with free for beta text`}
                        className="DataPrepSectionContainer__free-for-beta"/>
                  <Image image_url="content/data-free-for-beta-mobile/data-free-for-beta-mobile.png"
                        alt={`crossing out kortical platform price with free for beta text`}
                        className="d-sm-none w-100"/>
                  </React.Fragment>
                : null
              }
          </div>
          <PageCounter step={step} parts={['User details', 'Data policy', 'Welcome']}/>

          {children}
        </div>
      </div>
    </Layout>
  )
}
export default DataPrepSubpageWrapper;